<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn class="gray font-weight-bold" @click="onBack"
          ><v-icon left>mdi-arrow-left-circle</v-icon>戻る</v-btn
        >
      </div>
      <div class="pa-2">
        <contract-dialog-component
          ref="refDialogComponent"
          :shop="shop"
          @updated="updated"
        ></contract-dialog-component>
      </div>
      <div class="pa-2">
        <re-contract-dialog-component
          ref="refReContractDialogComponent"
          :shop="shop"
          @updated="updated"
        ></re-contract-dialog-component>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import contractDialogComponent from "./component/contractDialogComponent.vue";
import reContractDialogComponent from "./component/reContractDialogComponent.vue";

export default {
  components: {
    contractDialogComponent,
    reContractDialogComponent,
  },
  props: ["shop"],
  setup(props, ctx) {
    const refDialogComponent = ref();
    const refReContractDialogComponent = ref();

    const action = (params) => {
      switch (params.action) {
        case "edit":
          refDialogComponent.value.editDialog(params.value);
          break;
        case "recontract":
          refReContractDialogComponent.value.showDialog(params.value);
          break;
      }
    };

    const onBack = async () => {
      ctx.emit("on_back");
    };

    const updated = (shop_contract_data) => {
      ctx.emit("updated", shop_contract_data);
    };

    return {
      props,
      refDialogComponent,
      refReContractDialogComponent,
      action,
      onBack,
      updated,
    };
  },
};
</script>
