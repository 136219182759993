<template>
  <div>
    <!-- 見出し -->
    <div
      id="cast_header_bar"
      class="pa-2 blue--text blue lighten-5 d-flex align-center"
    >
      <div class="big mr-4">{{ shop.name }}様</div>
      <div>[ {{ shop.user_name }} ]</div>
    </div>

    <app-bar
      ref="refAppBar"
      :shop="shop"
      @on_back="onBack"
      @updated="updated"
    ></app-bar>

    <!-- テーブル -->
    <v-data-table
      :headers="headers"
      :items="shop.shopContractList"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
        <v-icon
          class="pa-1"
          @click="editItem(item)"
          :disabled="item.end_date <= closeDate || item.is_active == false"
        >
          mdi-pencil
        </v-icon>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.recontract="{ item }">
        <v-icon
          v-show="item.first_flg"
          class="pa-1"
          @click="recontractItem(item)"
        >
          mdi-content-copy
        </v-icon>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.end_date="{ item }">
        <span v-if="item.end_date != setting.auto_flag_end_date">
          {{ item.end_date }}
        </span>
        <span v-else>自動更新</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.plan_name="{ item }">
        <span
          v-for="(plan, index) in item.plan_names"
          :key="index"
          :class="
            item.is_active
              ? plan.code.slice(0, 2) == setting.plan_type_id_basic
                ? 'plan_name_primary'
                : 'plan_name_secondary'
              : 'plan_name_delete'
          "
        >
          {{ plan.name }}
        </span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.total_fixed_fee="{ item }">
        <div class="right-input">
          {{ item.total_fixed_fee.toLocaleString() }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
        <v-icon
          class="pa-1"
          @click="deleteItem(item)"
          :disabled="
            // 締日を見て判定
            item.end_date <= closeDate || item.is_active == false
          "
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import appBar from "./appBar.vue";
import utilDate from "@/common/utilDate";

export default defineComponent({
  components: {
    appBar,
  },
  props: ["shop_id"],
  setup(props, ctx) {
    const { router } = useRouter();
    const shopsRepository = repositoryFactory.get("shops");
    const plansRepository = repositoryFactory.get("plans");
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const systemPropsRepository = repositoryFactory.get("systemProps");

    const refAppBar = ref();
    const state = reactive({
      closeDate: "",
      planList: [],
      shop: {
        id: 0,
        user_id: 0,
        name: "",
        user_name: "",
        valid_contract: false,
        valid_option: null,
        shopContractList: [],
      },
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "複製",
          value: "recontract",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "プラン",
          value: "plan_name",
          sortable: true,
          class: "td_plan_name",
          width: 400,
        },
        {
          text: "契約開始",
          value: "start_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "契約終了",
          value: "end_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "月額合計",
          value: "total_fixed_fee",
          sortable: true,
          class: "td_total_fixed_fee",
        },
        {
          text: "コメント・補足",
          value: "description",
          sortable: false,
          class: "td_description",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
    });

    const editItem = (item) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        value: item.id,
      });
    };

    const recontractItem = (item) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "recontract",
        value: item.id,
      });
    };

    // データ削除
    const deleteItem = async (item) => {
      if (window.confirm("削除してよろしいですか？")) {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // 削除処理
        await shopContractsRepository
          .admin_delete(item.id)
          .then((response) => {
            if (response.data) {
              // データ削除OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:index.vue/deleteItem shopContractsRepository.admin_delete (" +
              error +
              ")"
            );
          });

        // shopテーブルの契約情報を更新する
        await updateShopValid(props.shop_id);

        init();

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 締め日を取得
      state.closeDate = await systemPropsRepository
        .admin_get_key("closing_date")
        .then((response) => {
          if (response.data) {
            return response.data.value;
          } else {
            return "";
          }
        })
        .catch((error) => {
          throw (
            "ERROR:index.vue/init systemPropsRepository.admin_get_key (" +
            error +
            ")"
          );
        });

      // Planリスト取得
      state.planList.splice(0);
      await plansRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            // データ追加OK
            Object.keys(response.data).forEach(function (key) {
              state.planList.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
            error +
            ")"
          );
        });

      await shopsRepository
        .admin_get_contract(props.shop_id)
        .then((response) => {
          if (response.data) {
            // console.log("response", response.data);
            // 取得したデータをオブジェクトに格納
            // 店舗情報
            state.shop.id = response.data.id;
            state.shop.user_id = response.data.user_id;
            state.shop.name = response.data.name;
            state.shop.user_name = response.data.user_name;
            state.shop.valid_contract = response.data.valid_contract;
            state.shop.valid_option = response.data.valid_option;
            state.shop.shopContractList.splice(0); // 店舗契約情報のリスト

            if ("shop_contract" in response.data) {
              if (response.data.shop_contract != null) {
                let first_flg = true;
                Object.keys(response.data.shop_contract).forEach(function (
                  key
                ) {
                  const contract = response.data.shop_contract[key];
                  const shop_contract = {
                    id: contract.id,
                    user_id: contract.user_id,
                    shop_id: contract.shop_id,
                    start_date: contract.start_date,
                    end_date: contract.end_date,
                    description:
                      (contract.is_active == false ? "【削除済】" : "") +
                      contract.description,
                    is_active: contract.is_active,
                    shopContractDetailList: [],
                    plan_names: [],
                    total_fixed_fee: 0,
                    first_flg: first_flg && contract.is_active,
                  };
                  first_flg =
                    first_flg && contract.is_active ? false : first_flg;
                  shop_contract.shopContractDetailList.splice(0); // 店舗契約明細情報のリスト
                  if ("detail" in contract) {
                    if (contract.detail != null) {
                      Object.keys(contract.detail).forEach(function (dkey) {
                        const detail = contract.detail[dkey];
                        const shop_contract_detail = {
                          id: detail.id,
                          shop_contract_id: detail.shop_contract_id,
                          plan_code: detail.plan_code,
                          initial_fee: detail.initial_fee,
                          fixed_fee: detail.fixed_fee,
                          quantity: detail.quantity,
                          description: detail.description,
                          sort: detail.sort,
                        };
                        const plan = state.planList.find(
                          (plan) => plan.code === detail.plan_code
                        );
                        shop_contract.plan_names.push({
                          code: plan.code,
                          name: plan.name,
                        });
                        shop_contract.total_fixed_fee +=
                          detail.fixed_fee * detail.quantity;
                        shop_contract.shopContractDetailList.push(
                          shop_contract_detail
                        );
                      });
                    }
                  }
                  state.shop.shopContractList.push(shop_contract);
                });
              }
            }
            // console.log("response.shop", state.shop);
          }
        })
        .catch((error) => {
          throw (
            "ERROR:contractEdit/index.vue/init shopsRepository.get_contract (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    const updateShopValid = async (shop_id) => {
      const params = {
        shop_id: shop_id,
        base_date: utilDate.getDateFormatLong(new Date()),
      };
      await shopsRepository
        .admin_update_valid(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:index.vue/updateShopValid shopsRepository.admin_update_valid (" +
            error +
            ")"
          );
        });
    };

    init();

    const updated = () => {
      init();
    };

    const onBack = () => {
      router.back();
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      utilDate,
      setting,
      ...toRefs(state),
      editItem,
      recontractItem,
      deleteItem,
      init,
      updated,
      onBack,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_plan_name {
  min-width: 150px;
  max-width: 400px;
}
::v-deep .td_date,
::v-deep .td_total_fixed_fee {
  min-width: 120px;
  width: 120px;
}
::v-deep .td_description {
  min-width: 200px;
  width: 300px;
}

@media screen and (max-width: 600px) {
  ::v-deep .td_plan_name {
    min-width: 150px;
    width: 200px;
  }
  ::v-deep .td_date,
  ::v-deep .td_total_fixed_fee {
    min-width: 90px;
    width: 90px;
  }
  .overflow-text {
    max-width: 200px;
  }
}
.overflow-text::-webkit-scrollbar {
  display: none;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.plan_name_delete {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #9e9e9e;
}
</style>
