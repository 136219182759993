var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-2 blue--text blue lighten-5 d-flex align-center",attrs:{"id":"cast_header_bar"}},[_c('div',{staticClass:"big mr-4"},[_vm._v(_vm._s(_vm.shop.name)+"様")]),_c('div',[_vm._v("[ "+_vm._s(_vm.shop.user_name)+" ]")])]),_c('app-bar',{ref:"refAppBar",attrs:{"shop":_vm.shop},on:{"on_back":_vm.onBack,"updated":_vm.updated}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shop.shopContractList,"item-key":"id","mobile-breakpoint":0,"items-per-page":100,"footer-props":{
      'items-per-page-options': [100, 200, 400, -1],
      'items-per-page-text': '表示件数',
    }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":item.end_date <= _vm.closeDate || item.is_active == false},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.recontract",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.first_flg),expression:"item.first_flg"}],staticClass:"pa-1",on:{"click":function($event){return _vm.recontractItem(item)}}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.end_date",fn:function(ref){
    var item = ref.item;
return [(item.end_date != _vm.setting.auto_flag_end_date)?_c('span',[_vm._v(" "+_vm._s(item.end_date)+" ")]):_c('span',[_vm._v("自動更新")])]}},{key:"item.plan_name",fn:function(ref){
    var item = ref.item;
return _vm._l((item.plan_names),function(plan,index){return _c('span',{key:index,class:item.is_active
            ? plan.code.slice(0, 2) == _vm.setting.plan_type_id_basic
              ? 'plan_name_primary'
              : 'plan_name_secondary'
            : 'plan_name_delete'},[_vm._v(" "+_vm._s(plan.name)+" ")])})}},{key:"item.total_fixed_fee",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.total_fixed_fee.toLocaleString())+" ")])]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":// 締日を見て判定
          item.end_date <= _vm.closeDate || item.is_active == false},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }