<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >現在の契約情報を元に、新しい契約期間で複製します</span
          >
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <h3 id="title_head">元の契約情報</h3>
          <p>こちらの契約が元になります。</p>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" sm="4">
                <v-text-field
                  v-model="item.start_date"
                  name="start_date"
                  type="date"
                  label="開始日"
                  outlined
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="4">
                <v-text-field
                  v-model="item.end_date"
                  name="end_date"
                  :success="true"
                  type="date"
                  label="終了日"
                  outlined
                  :disabled="item.auto_flg"
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="4">
                <v-switch
                  v-model="item.auto_flg"
                  label="自動更新"
                  readonly
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" sm="4">
                <p class="text-h6 mb-0">月額合計</p>
                <p class="text-right">
                  <span class="text-h5">
                    {{ item.total_fee.toLocaleString() }}
                  </span>
                  円
                </p>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            id="shop_contract_detail"
            :headers="headers"
            :items="item.shopContractDetailList"
            item-key="random_id"
            :mobile-breakpoint="0"
            :items-per-page="-1"
            :footer-props="{
              'items-per-page-options': [-1],
              'items-per-page-text': '表示件数',
            }"
            hide-default-footer
          >
            <!-- eslint-disable-next-line -->
              <template v-slot:footer.page-text="props">
              {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                props.itemsLength
              }}件
            </template>
            <!-- eslint-disable-next-line -->
              <template v-slot:item.initial_fee="{ item }">
              <div class="right-input">
                {{ item.initial_fee.toLocaleString() }}
              </div>
            </template>
            <!-- eslint-disable-next-line -->
              <template v-slot:item.fixed_fee="{ item }">
              <div class="right-input">
                {{ item.fixed_fee.toLocaleString() }}
              </div>
            </template>
            <!-- eslint-disable-next-line -->
              <template v-slot:item.quantity="{ item }">
              <div class="right-input">
                {{ item.quantity.toLocaleString() }}
              </div>
            </template>
            <!-- eslint-disable-next-line -->
              <template v-slot:item.total_fixed_fee="{ item }">
              <div class="right-input">
                {{ item.total_fixed_fee.toLocaleString() }}
              </div>
            </template>
            <template v-slot:no-data> 表示するデータがありません </template>
          </v-data-table>
          <p class="ma-6 text-center">
            <v-icon class="pa-6" x-large> mdi-arrow-down-bold </v-icon>
          </p>
          <ValidationObserver ref="observer">
            <h3 id="title_head">新しい契約情報の期間</h3>
            <div>
              同じ内容で契約を作ります。新しい契約期間を指定してください。
              <ul class="text-red mb-4">
                <li>
                  現在も契約が続いている場合、新しい契約期間の前日が元契約の終了日にセットされます。
                </li>
                <li>
                  新しい契約期間の開始日が元契約の期間中だった場合、新しい契約期間の前日が元契約の終了日にセットされます。
                </li>
              </ul>
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="3" sm="4">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="開始日"
                    rules="required"
                  >
                    <v-text-field
                      ref="start_date"
                      v-model="new_start_date"
                      name="start_date"
                      :error-messages="errors"
                      :success="valid"
                      type="date"
                      label="開始日"
                      hint="契約の開始日を設定して下さい[必須項目]"
                      persistent-hint
                      outlined
                      :min="closeDatePlusOne"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3" sm="4">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="終了日"
                    :rules="`${new_auto_flg ? '' : 'required'}`"
                  >
                    <v-text-field
                      ref="end_date"
                      v-model="new_end_date"
                      name="end_date"
                      :error-messages="errors"
                      :success="valid"
                      type="date"
                      label="終了日"
                      hint="契約の終了日を設定して下さい[必須項目]"
                      persistent-hint
                      outlined
                      :disabled="new_auto_flg"
                      :min="closeDatePlusOne"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3" sm="4">
                  <v-switch
                    v-model="new_auto_flg"
                    label="自動更新"
                    @change="onChangeAutoFlg"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  props: ["shop"],
  setup(props, ctx) {
    const shopsRepository = repositoryFactory.get("shops");
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const shopContractDetailsRepository = repositoryFactory.get(
      "shopContractDetails"
    );
    const plansRepository = repositoryFactory.get("plans");
    const systemPropsRepository = repositoryFactory.get("systemProps");
    const unclaimedContractsRepository =
      repositoryFactory.get("unclaimedContracts");

    const state = reactive({
      closeDate: "",
      closeDatePlusOne: "",
      userList: props.users,
      dialog: false, // ダイアログ表示用
      planList: [],
      planSelectList: [],
      new_start_date: null,
      new_end_date: null,
      new_auto_flg: true,
      item: {
        id: 0,
        user_id: 0,
        shop_id: 0,
        start_date: null,
        end_date: null,
        description: "",
        auto_flg: false,
        shopContractDetailList: [],
        total_fee: 0,
      },
      detailItem: {
        random_id: 0,
        id: 0,
        shop_contract_id: 0,
        plan_code: "",
        initial_fee: null,
        fixed_fee: null,
        quantity: null,
        sort: 9999,
        plan_name: "",
      },
      headers: [
        {
          text: "種別",
          value: "plan_type_name",
          sortable: false,
          class: "td_plan_type_name",
        },
        {
          text: "名前",
          value: "plan_name",
          sortable: false,
          class: "td_plan_name",
        },
        {
          text: "初期費用",
          value: "initial_fee",
          sortable: false,
          class: "td_initial_fee",
        },
        {
          text: "単価",
          value: "fixed_fee",
          sortable: false,
          class: "td_fixed_fee",
        },
        {
          text: "個数",
          value: "quantity",
          sortable: false,
          class: "td_quantity",
        },
        {
          text: "月額合計",
          value: "total_fixed_fee",
          sortable: false,
          class: "td_total_fixed_fee",
        },
      ],
    });

    // 新規作成
    const showDialog = async (currentShopContractId) => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 初期値を設定 契約情報取得
      await getContract(currentShopContractId);

      // let day = new Date();
      // let nextDay = null;
      // if (state.item.end_date == null) {
      //   // 翌月の1日を取得
      //   nextDay = new Date(day.getFullYear(), day.getMonth() + 1, 1);
      // } else {
      //   day = new Date(state.item.end_date);
      //   if (day > today) {
      //     // state.item.end_dateの翌日を取得
      //     console.log("終了日＋1");
      //     nextDay = new Date(
      //       day.getFullYear(),
      //       day.getMonth(),
      //       day.getDate() + 1
      //     );
      //   } else {
      //     console.log("翌月＋1");
      //     nextDay = new Date(day.getFullYear(), day.getMonth() + 1, 1);
      //   }
      // }
      // state.new_start_date = "";

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.user_id = 0;
      state.item.name = "";
      state.item.sort = 9999;
    };

    const getContract = async (shopContractId) => {
      await shopContractsRepository
        .admin_get_with_details(shopContractId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            state.item.id = response.data.id;
            state.item.user_id = response.data.user_id;
            state.item.shop_id = response.data.shop_id;
            state.item.start_date = response.data.start_date;
            if (response.data.end_date == setting.auto_flag_end_date) {
              state.item.end_date = null;
              state.item.auto_flg = true;
            } else {
              state.item.end_date = response.data.end_date;
              state.item.auto_flg = false;
            }
            state.item.description = response.data.description;
            state.item.shopContractDetailList.splice(0);
            let tmp_total_fee = 0;
            if ("detail" in response.data) {
              if (response.data.detail != null) {
                Object.keys(response.data.detail).forEach(function (key) {
                  const detail = response.data.detail[key];
                  const total_fixed_fee =
                    (detail.fixed_fee == null
                      ? state.planList.find((x) => x.code === detail.plan_code)
                          .default_fixed_fee
                      : detail.fixed_fee) * detail.quantity;
                  const shop_contract_detail = {
                    random_id: detail.id,
                    id: detail.id,
                    shop_contract_id: detail.shop_contract_id,
                    plan_code: detail.plan_code,
                    initial_fee: detail.initial_fee,
                    fixed_fee: detail.fixed_fee,
                    quantity: detail.quantity,
                    total_fixed_fee: total_fixed_fee,
                    sort: detail.sort,
                    plan_type_name: state.planList.find(
                      (x) => x.code === detail.plan_code
                    ).type_label,
                    plan_name: state.planList.find(
                      (x) => x.code === detail.plan_code
                    ).name,
                  };
                  state.item.shopContractDetailList.push(shop_contract_detail);
                  tmp_total_fee += total_fixed_fee;
                });
              }
            }
            state.item.total_fee = tmp_total_fee;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:contractDialogComponent.vue/editDialog shopContractsRepository.admin_get_with_details (" +
            error +
            ")"
          );
        });
      state.dialog = true;
    };

    const onChangeAutoFlg = () => {
      if (state.item.auto_flg) {
        state.item.end_date = null;
      }
    };

    const updateShopValid = async (shop_id) => {
      const params = {
        shop_id: shop_id,
        base_date: utilDate.getDateFormatLong(new Date()),
      };
      await shopsRepository
        .admin_update_valid(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:contractDialogComponent.vue/updateShopValid shopsRepository.admin_update_valid (" +
            error +
            ")"
          );
        });
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
        return;
      } else {
        // 新契約の開始日と終了日の論理チェック
        console.log(state.new_start_date, state.new_end_date);
        if (!state.new_auto_flg && state.new_start_date > state.new_end_date) {
          alert(
            "新しい契約の終了日が開始日以前の日付です。契約期間を確認して下さい。"
          );
          ctx.refs.start_date.focus();
          return;
        }
        // 新契約の開始日が元契約の開始日より過去の場合エラーにする
        if (state.new_start_date <= state.item.start_date) {
          alert(
            "新しい契約の開始日が元契約の開始日より過去です。開始日を確認して下さい。"
          );
          ctx.refs.start_date.focus();
          return;
        }

        // 確認メッセージ作成
        let message = "";
        let end_date_update = false;
        if (state.item.end_date == null) {
          message =
            "元契約の終了日には、新しい契約開始日の前日がセットされます。\n";
          end_date_update = true;
        } else {
          if (state.item.end_date >= state.new_start_date) {
            message =
              "元契約の終了日より以前の日付が新しい契約開始日指定されました。\n元契約の終了日には、新しい契約開始日の前日がセットされます。\n";
            end_date_update = true;
          }
        }

        if (!window.confirm(message + "保存してよろしいですか？")) {
          return false;
        }

        // 元契約の終了日を書き換える必要がある場合、更新する
        if (end_date_update) {
          // 元契約の更新
          // new_start_dateから1日引いた日付を元契約の終了日にセットする
          const end_date = new Date(state.new_start_date);
          end_date.setDate(end_date.getDate() - 1);

          const params = {
            id: state.item.id,
            user_id: state.item.user_id,
            shop_id: state.item.shop_id,
            start_date: state.item.start_date,
            end_date: utilDate.getDateFormatLong(end_date),
            description: state.item.description,
            auto_flg: state.item.auto_flg,
          };
          // 更新
          await shopContractsRepository
            .admin_update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
              }
            })
            .catch((error) => {
              throw (
                "ERROR:contractDialogComponent.vue/onSubmit shopContractsRepository.admin_update (" +
                error +
                ")"
              );
            });
        }

        // 元契約をベースに新しい期間の契約情報を追加する
        const new_params = {
          id: 0,
          user_id: state.item.user_id,
          shop_id: state.item.shop_id,
          start_date: state.new_start_date,
          end_date:
            state.new_end_date == null
              ? setting.auto_flag_end_date
              : state.new_end_date,
          description: state.item.description,
          auto_flg: state.new_auto_flg,
        };
        await shopContractsRepository
          .admin_create(new_params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              state.item.id = response.data.id; // idをセット
            }
          })
          .catch((error) => {
            throw (
              "ERROR:contractDialogComponent.vue/onSubmit shopContractsRepository.admin_create (" +
              error +
              ")"
            );
          });
        // 元契約をベースに新しい契約明細を追加する
        for (
          let index = 0;
          index < state.item.shopContractDetailList.length;
          index++
        ) {
          const detail = state.item.shopContractDetailList[index];
          detail.shop_contract_id = state.item.id;
          const params = {
            id: 0,
            shop_contract_id: detail.shop_contract_id,
            plan_code: detail.plan_code,
            initial_fee: detail.initial_fee,
            fixed_fee: detail.fixed_fee,
            quantity: detail.quantity,
            sort: index + 1,
          };
          await shopContractDetailsRepository
            .admin_create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                detail.id = response.data.id; // idをセット
              }
            })
            .catch((error) => {
              throw (
                "ERROR:contractDialogComponent.vue/onSubmit shop_contract_detailsRepository.admin_create (" +
                error +
                ")"
              );
            });
        }

        // 未請求契約の場合、未請求契約情報を追加・更新する
        // 現在の締め日＋1ヶ月以内の開始日を持つ契約を対象とする
        const base_date = new Date(state.closeDate);
        base_date.setDate(1);
        base_date.setMonth(base_date.getMonth() + 2);
        // billing_month用に締め日＋2ヶ月の年月を取得しておく
        const tmpBillingMonth = `${base_date.getFullYear()}${(
          "0" +
          (base_date.getMonth() + 1)
        ).slice(-2)}`;
        // 締め日＋1ヶ月の末尾を取得
        base_date.setDate(0);

        console.log("base_date", utilDate.getDateFormatLong(base_date));
        console.log("state.start_date", state.new_start_date);

        // 現在の締め日＋1ヶ月以内の開始日か判定
        if (state.new_start_date <= utilDate.getDateFormatLong(base_date)) {
          // 未請求契約情報を追加・更新する
          const [sYear, sMonth, sDay] = state.new_start_date.split("-");
          const saveParams = {
            shop_contract_id: state.item.id,
            billing_month: Number(tmpBillingMonth),
            service_month: Number(`${sYear}${sMonth}`),
          };
          console.log(saveParams);
          await unclaimedContractsRepository
            .admin_save(saveParams)
            .then((response) => {
              if (response.data) {
                // データ追加OK
              }
            })
            .catch((error) => {
              throw "ERROR:unclaimedContractsRepository.save (" + error + ")";
            });
        }

        // shopテーブルの契約情報を更新する
        await updateShopValid(state.item.shop_id);

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        cancelDialog();

        ctx.emit("updated", state.item);
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearDialog();
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    // 初期化
    const init = async () => {
      store.dispatch("loadingIcon/showIcon");

      // 締め日を取得
      state.closeDate = await systemPropsRepository
        .admin_get_key("closing_date")
        .then((response) => {
          if (response.data) {
            return response.data.value;
          } else {
            return "";
          }
        })
        .catch((error) => {
          throw (
            "ERROR:index.vue/init systemPropsRepository.admin_get_key (" +
            error +
            ")"
          );
        });
      // 開始日のmin用に締め日の翌日をセット
      const plus = new Date(state.closeDate);
      plus.setDate(plus.getDate() + 1);
      state.closeDatePlusOne = utilDate.getDateFormatLong(plus);

      // Planリスト取得
      state.planList.splice(0);
      state.planSelectList.splice(0);
      await plansRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            // データ追加OK
            Object.keys(response.data).forEach(function (key) {
              const plan = response.data[key];
              const type_label = setting.plan_type_label.find(
                (x) => x.value === plan.code.slice(0, 2)
              ).text;

              set(plan, "type_label", type_label);
              state.planList.push(plan);

              const selectItem = {
                value: plan.code,
                text: type_label + " : " + plan.name,
              };
              state.planSelectList.push(selectItem);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
            error +
            ")"
          );
        });

      clearDialog();

      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      getContract,
      onSubmit,
      cancelDialog,
      cancelCastDialog,
      showDialog,
      onChangeAutoFlg,
    };
  },
});
</script>

<style scoped>
::v-deep #shop_contract_detail thead {
  background-color: #fff3e0 !important;
}
::v-deep .td_plan_type_name {
  min-width: 110px;
  width: 150px;
}
::v-deep .td_plan_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_initial_fee,
::v-deep .td_fixed_fee,
::v-deep .td_quantity,
::v-deep .td_total_fixed_fee {
  min-width: 100px;
  width: 100px;
}
</style>
